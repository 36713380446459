<app-navbar *ngIf="mostrarFooter"></app-navbar>
<router-outlet></router-outlet>
<app-footer *ngIf="mostrarFooter"></app-footer>
<app-moda-esp
	[dataM]="dataM"
	[ejecu]="openSessionExpire"
	[botonPorDefecto]="viewBtnExpire"
  [textoBoton]="dataM.texBtn1"
	(clickBtn1)="logout()"
></app-moda-esp>
<app-moda-esp
[dataM]="dataM2"
[ejecu]="openModalCuentaRegresiva"
[closeEjecu]="closeModalInativity"
[botonPorDefecto]="viewBtnCuentRegresiva"
[textoBoton]="dataM.texBtn1"
(clickBtn1)="cerrarModalCuentaRegresiva()"
></app-moda-esp>
<app-modal
  [dataM]="dataModal"
  [botonPorDefecto]="viewBtnModal"
  [ejecu]="duplicateSession"
  (clickBtn1)="logout2()">
</app-modal>

import { Action } from "@ngrx/store";

export enum EAuthorize {
	LOAD_AUTHORIZE = "[Authorize] get Authorize Success"
}

export class LoadAuthorize implements Action {
	readonly type = EAuthorize.LOAD_AUTHORIZE;
	constructor(public payload: any) {}
}

export type AuthorizeAction =
	| LoadAuthorize;

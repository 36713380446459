import { IAlternatives, IAlternativeSelected, ICustomerData} from '../layout/cobranza-digital/store/states/alternatives.state';
import { IAutenticated, IData } from '../layout/cobranza-digital/store/states/autenticated.state';

export const ALTERSELEC :IAlternativeSelected = {
    "packageName": "Paquete 1",
    "needCode": 1,
    "alternativeConditions": "Paquete 1: Cuota consolidada mensual (capital + intereses corrientes) despues de periodo de gracia $ 1,723,173. Tasa DTF TA + 19.76%, periodo de gracia de 6 meses y nuevo plazo total de 41 meses para la obligacion incluido el periodo de gracia. Cuota mensual durante el periodo de gracia de $ 655,672.",
    "creditsNumber": 4,
    "feeDuringGracePeriod": "$675.552,00",
    "newAmortizationDescription": "mensual",
    "details": {
        "newConditions": {
            "term": "41",
            "gracePeriod": "6",
            "newFee": "$655.672,00",
            "feeAfterGracePeriod": "0",
            "unifiedValueFNG": "$32.405.727,00",
            "feeFNG": "8",
            "newCapital": "$32.405.727,00",
            "rate": "TASA FIJA MV 25.1926253%"
        },
        "creditsIncluded": [
            {
                "nameObligation": "Tarjeta de crédito",
                "loanNumber": "******1243",
                "loanNumberComplete": "0010002344"
            },
            {
                "nameObligation": "Sobregiro",
                "loanNumber": "******7649",
                "loanNumberComplete": "0020002344"
            },
            {
                "nameObligation": "Crédito Hipotecario",
                "loanNumber": "******2436",
                "loanNumberComplete": "0030002344"
            },
            {
                "nameObligation": "Crédito libre inversión",
                "loanNumber": "******0896",
                "loanNumberComplete": "0040002344"
            }
        ]
    }
}

export const ALTER1 : IAlternatives = {
  "customerData": {
      "expirationDate": "octubre 31 de 2040",
      "alternativeType": "Modificacion",
      "state": "",
      "customerName": "pending mdm: AFPS",
      "mobilePhone": "pending mdm: 12345",
      "callDays": "nn",
      "actionCode": 1190,
      "opportunityId": "X43X5",
      "obligaciones": [""],
      "newMobilePhone" : ""
  },
  "offerList": [
      {
          "packageName": "Paquete 1",
          "needCode": 1,
          "alternativeConditions": "Paquete 1: Cuota consolidada mensual (capital + intereses corrientes) despues de periodo de gracia $ 1,723,173. Tasa DTF TA + 19.76%, periodo de gracia de 6 meses y nuevo plazo total de 41 meses para la obligacion incluido el periodo de gracia. Cuota mensual durante el periodo de gracia de $ 655,672.",
          "creditsNumber": 4,
          "feeDuringGracePeriod": "$675.552,00",
          "newAmortizationDescription": "mensual",
          "details": {
              "newConditions": {
                  "term": "41",
                  "gracePeriod": "6",
                  "newFee": "$655.672,00",
                  "feeAfterGracePeriod": "0",
                  "unifiedValueFNG": "$32.405.727,00",
                  "feeFNG": "8",
                  "newCapital": "$32.405.727,00",
                  "rate": "TASA FIJA MV 25.1926253%"
              },
              "creditsIncluded": [
                  {
                      "nameObligation": "Tarjeta de crédito",
                      "loanNumber": "******1243",
                      "loanNumberComplete": "0010002344"
                  },
                  {
                      "nameObligation": "Sobregiro",
                      "loanNumber": "******7649",
                      "loanNumberComplete": "0020002344"
                  },
                  {
                      "nameObligation": "Crédito Hipotecario",
                      "loanNumber": "******2436",
                      "loanNumberComplete": "0030002344"
                  },
                  {
                      "nameObligation": "Crédito libre inversión",
                      "loanNumber": "******0896",
                      "loanNumberComplete": "0040002344"
                  }
              ]
          }
      },
      {
          "packageName": "Paquete 2",
          "needCode": 1,
          "alternativeConditions": "Paquete 1: Cuota consolidada mensual (capital + intereses corrientes) despues de periodo de gracia $ 1,723,173. Tasa DTF TA + 19.76%, periodo de gracia de 6 meses y nuevo plazo total de 41 meses para la obligacion incluido el periodo de gracia. Cuota mensual durante el periodo de gracia de $ 655,672.",
          "creditsNumber": 3,
          "feeDuringGracePeriod": "$181.119,00",
          "newAmortizationDescription": "semestral",
          "details": {
              "newConditions": {
                  "term": "35",
                  "gracePeriod": "3",
                  "newFee": "$151.419,00",
                  "feeAfterGracePeriod": "0",
                  "unifiedValueFNG": "$8.250.516,00",
                  "feeFNG": "7",
                  "newCapital": "$8.250.516,00",
                  "rate": "DTF TA 20.280000700000002%"
              },
              "creditsIncluded": [
                  {
                      "nameObligation": "Tarjeta de crédito",
                      "loanNumber": "******2344",
                      "loanNumberComplete": "0050002344"
                  },
                  {
                      "nameObligation": "Sobregiro",
                      "loanNumber": "******0856",
                      "loanNumberComplete": "0060000856"
                  },
                  {
                      "nameObligation": "Crédito Hipotecario",
                      "loanNumber": "******0098",
                      "loanNumberComplete": "0070000098"
                  }
              ]
          }
      },
      {
          "packageName": "Paquete 3",
          "needCode": 1,
          "alternativeConditions": "Paquete 1: Cuota consolidada mensual (capital + intereses corrientes) despues de periodo de gracia $ 1,723,173. Tasa DTF TA + 19.76%, periodo de gracia de 6 meses y nuevo plazo total de 41 meses para la obligacion incluido el periodo de gracia. Cuota mensual durante el periodo de gracia de $ 655,672.",
          "creditsNumber": 6,
          "feeDuringGracePeriod": "$171.616,00",
          "newAmortizationDescription": "anual",
          "details": {
              "newConditions": {
                  "term": "35",
                  "gracePeriod": "12",
                  "newFee": "$161.519,00",
                  "feeAfterGracePeriod": "0",
                  "unifiedValueFNG": "$8.250.516,00",
                  "feeFNG": "0.0",
                  "newCapital": "$8.250.516,00",
                  "rate": "DTF TA 20.280000700000002%"
              },
              "creditsIncluded": [
                  {
                      "nameObligation": "Tarjeta de crédito",
                      "loanNumber": "******0896",
                      "loanNumberComplete": "0080002344"
                  },
                  {
                      "nameObligation": "Sobregiro",
                      "loanNumber": "******0985",
                      "loanNumberComplete": "0090002344"
                  },
                  {
                      "nameObligation": "Tarjeta de crédito",
                      "loanNumber": "******1123",
                      "loanNumberComplete": "0100002344"
                  },
                  {
                      "nameObligation": "Sobregiro",
                      "loanNumber": "******4567",
                      "loanNumberComplete": "0110002344"
                  },
                  {
                      "nameObligation": "Tarjeta de crédito",
                      "loanNumber": "******3475",
                      "loanNumberComplete": "0120002344"
                  },
                  {
                      "nameObligation": "Sobregiro",
                      "loanNumber": "******9785",
                      "loanNumberComplete": "0130002344"
                  }
              ]
          }
      }
  ],
  "alternativeSelected": ALTERSELEC,
  "index": 2,
	"error": true,
  "code": "000"
}
export const ALTER2 : IAlternatives = {
  "customerData": {
    "expirationDate": "octubre 31 de 2040",
    "alternativeType": "Modificacion",
    "state": "",
    "customerName": "pending mdm: AFPS",
    "mobilePhone": "pending mdm: 12345",
    "callDays": "nn",
    "actionCode": 1190,
    "opportunityId": "X43X5",
    "obligaciones": [""],
    "newMobilePhone" : ""
  },
  "offerList": [
      {
          "packageName": "Paquete 1",
          "needCode": 1,
          "alternativeConditions": "Paquete 1: Cuota consolidada mensual (capital + intereses corrientes) despues de periodo de gracia $ 1,723,173. Tasa DTF TA + 19.76%, periodo de gracia de 6 meses y nuevo plazo total de 41 meses para la obligacion incluido el periodo de gracia. Cuota mensual durante el periodo de gracia de $ 655,672.",
          "creditsNumber": 4,
          "feeDuringGracePeriod": "$675.552,00",
          "newAmortizationDescription": "mensual",
          "details": {
              "newConditions": {
                  "term": "41",
                  "gracePeriod": "6",
                  "newFee": "$655.672,00",
                  "feeAfterGracePeriod": "0",
                  "unifiedValueFNG": "$32.405.727,00",
                  "feeFNG": "8",
                  "newCapital": "$32.405.727,00",
                  "rate": "TASA FIJA MV 25.1926253%"
              },
              "creditsIncluded": [
                  {
                      "nameObligation": "Tarjeta de crédito",
                      "loanNumber": "******1243",
                      "loanNumberComplete": "0010002344"
                  },
                  {
                      "nameObligation": "Sobregiro",
                      "loanNumber": "******7649",
                      "loanNumberComplete": "0020002344"
                  },
                  {
                      "nameObligation": "Crédito Hipotecario",
                      "loanNumber": "******2436",
                      "loanNumberComplete": "0030002344"
                  },
                  {
                      "nameObligation": "Crédito libre inversión",
                      "loanNumber": "******0896",
                      "loanNumberComplete": "0040002344"
                  }
              ]
          }
      }
  ],
  "alternativeSelected": ALTERSELEC,
  "index": 1,
	"error": true,
  "code": "000"
}

export const ALTER3 : IAlternatives = {
  "customerData": {
    "expirationDate": "octubre 31 de 2040",
    "alternativeType": "Consolidacion",
    "state": "",
    "customerName": "pending mdm: AFPS",
    "mobilePhone": "pending mdm: 12345",
    "callDays": "nn",
    "actionCode": 1190,
    "opportunityId": "X43X5",
    "obligaciones": [""],
    "newMobilePhone" : ""
  },
  "offerList": [
      {
          "packageName": "Paquete 1",
          "needCode": 1,
          "alternativeConditions": "Paquete 1: Cuota consolidada mensual (capital + intereses corrientes) despues de periodo de gracia $ 1,723,173. Tasa DTF TA + 19.76%, periodo de gracia de 6 meses y nuevo plazo total de 41 meses para la obligacion incluido el periodo de gracia. Cuota mensual durante el periodo de gracia de $ 655,672.",
          "creditsNumber": 4,
          "feeDuringGracePeriod": "$675.552,00",
          "newAmortizationDescription": "mensual",
          "details": {
              "newConditions": {
                  "term": "41",
                  "gracePeriod": "6",
                  "newFee": "$655.672,00",
                  "feeAfterGracePeriod": "0",
                  "unifiedValueFNG": "$32.405.727,00",
                  "feeFNG": "8",
                  "newCapital": "$32.405.727,00",
                  "rate": "TASA FIJA MV 25.1926253%"
              },
              "creditsIncluded": [
                  {
                      "nameObligation": "Tarjeta de crédito",
                      "loanNumber": "******1243",
                      "loanNumberComplete": "0010002344"
                  },
                  {
                      "nameObligation": "Sobregiro",
                      "loanNumber": "******7649",
                      "loanNumberComplete": "0020002344"
                  },
                  {
                      "nameObligation": "Crédito Hipotecario",
                      "loanNumber": "******2436",
                      "loanNumberComplete": "0030002344"
                  },
                  {
                      "nameObligation": "Crédito libre inversión",
                      "loanNumber": "******0896",
                      "loanNumberComplete": "0040002344"
                  }
              ]
          }
      }
  ],
  "alternativeSelected": ALTERSELEC,
  "index": 1,
	"error": true,
  "code": "000"
}

export const AUTEN_YES : IAutenticated = {
  data: {
    documentType: "CC",
    documentNumber: "1998233913",
    lastEntryHour: "13:50:13",
    lastEntryDate: "2021/10/17",
    ipClient: "192.16.1.12",
    customerName: "Mateo",
    sessionId:"",
    sessionToken: "",
    transactionToken:""
  },
  error: false,
  canal : ""
}

export const AUTEN_ERR : IAutenticated = {
  data: {
    documentType: "CC",
    documentNumber: "1998233913",
    lastEntryHour: "13:50:13",
    lastEntryDate: "2021/10/17",
    ipClient: "192.16.1.12",
    customerName: "Mateo",
    sessionId:"",
    sessionToken: "",
    transactionToken:""
  },
  error: true,
  canal : ""
}

export const AUTEN_NO : any = {
  "timestamp": "2022-05-10T16:45:04.485991",
  "source": "ms-authentication",
  "code": "AUTH0101",
  "message": "Error al consumir logout",
  "details": "Error al consumir logout"
}


export const LOG1 : IData = {
  customerName: "",
  documentNumber:"",
  documentType:"",
  ipClient:"",
  lastEntryDate:"",
  lastEntryHour:"",
  sessionId:"",
  sessionToken:"",
  transactionToken:""
}
export const LOG2 : ICustomerData = {
  actionCode:0,
  alternativeType:"",
  callDays:"",
  customerName:"",
  expirationDate:"",
  mobilePhone:"",
  opportunityId:"",
  state:"",
  obligaciones:[""],
  newMobilePhone: ""
}

export const BODYLOGS : any = {
  technicalSection:{
      idSesion: "",
      ipClient: "",
      fechaHoraTransaccion: "", //yo
      pasoFuncional: "",
      deviceBrowser: "",
      sistemaOperativo: "",
      dispositivo: ""
  },
  clientSection:{
      tipoDocumentoId: "",
      numeroDocumento: "",
      nombreCortoCliente: "",
      telefonoCelular: ""
  },
  errorSection:{
      codigoError:"",
      descripcionError:"",
      codigoErrorFuncional:"",
      descripcionErrorFuncional:"",
      tipoExcepcion:"",
      servicio:"",
      operacionServicio:""
  }
}

import { Component, ElementRef, HostListener, Inject, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import {
	DATA_EXPIROSESION,
	DATA_CUENTA_REGRE
} from "./core/components/moda-esp/constants";

import { LoadClientOkFailed } from './layout/cobranza-digital/store/actions/clientOk.action';

import {
	DataModal,
	DataModalEsp
} from "src/assets/interfaces/interfaces.state";
import { DATA_MODAL_SESSION_DUPLICATE } from "./core/components/modal/constants";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/states/app.state";
import { CobranzaService } from "./services/cobranza.service";
import { LOCATION_TOKEN } from "./utils/location";
import { IData } from './layout/cobranza-digital/store/states/autenticated.state';
import { IClientOk } from './layout/cobranza-digital/store/states/clientOk.state';
import {
	LoadAutenticatedSuccess,
	LoadAutenticatedFailed
} from "./layout/cobranza-digital/store/actions/autenticated.action";
import { LOG1, LOG2} from "./utils/constOfTest";
import { generateLog, ChangeGuard } from "./utils/utils";


const CIEN_PORCIENTO: number = 100;
const ZERO: number = 0;
const SIXTY: number = 60;
const THOUSAND: number = 1000;
const MAX_TIME_INACTIVITY: number = 420; //7 MINUTOS 420
const ALERT_TIME_INACTIVITY: number = 120; //2 MINUTOS 120
@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	providers: [
		{ provide: LOCATION_TOKEN, useValue: window.location }
	]
})
export class AppComponent implements OnInit {
  @HostListener('window:pageshow', ['$event'])
  leaving($event) {
    if ($event.persisted) {
      this.router.navigate(["/logout"]);
    }else{
      // This is intentional
    }
  }
	dataModal: DataModal = DATA_MODAL_SESSION_DUPLICATE;
	dataM: DataModalEsp = DATA_EXPIROSESION;
	dataM2: DataModalEsp = DATA_CUENTA_REGRE;
  viewBtnModal : boolean = false;
	viewBtnExpire: boolean = false;
	viewBtnCuentRegresiva: boolean = false;
  public closeModalInativity: boolean = false;

  openModalSessionExpire: boolean = false;
  duplicateSession: boolean = false;
  openModalCuentaRegresiva: boolean = false;

	minutes: string;
	seconds: string;

	openSessionExpire: boolean = false;
	openCuentRegresiva: boolean = false;


	title = "Cobranza digital"
	timeoutHandle: any;
	timeoutHandle2: any;
	timeoutHandle3: any;

	min: string;
	sec: string;
	time: string;
	percentage: any;

	guard: ChangeGuard;
	mostrarFooter: boolean = true;
	documentNumber: string;
	documentType: string;

	public controlInactivity: any;

	public maxTimeInactivity: number = MAX_TIME_INACTIVITY;
	public alertTimeInactivity: number = ALERT_TIME_INACTIVITY;
	public isMovingMouse: boolean = false;
	public countSecondsInactivity: number = ZERO;

  redirection : string;
  mostrarCarga: boolean= false;
  textoCarga : string = "Por favor espera mientras validamos la información";
  propiedadesLog: any = {
    canal : ""
  };
  count: number = 0;
  countExpire: number = 0;
  moviendo: boolean = false;


	constructor(
		private cobranzaService: CobranzaService,
		private router: Router,
		private _store: Store<IAppState>,
    @Inject(LOCATION_TOKEN) public location: Location,
    private _elementRef: ElementRef,
		private route: ActivatedRoute
	) {
    this.guard = new ChangeGuard(this._store, this.location);
  }

	ngOnInit() {
    this._elementRef.nativeElement.removeAttribute("ng-version");
    const querystring = window.location.search;
    const params = new URLSearchParams(querystring)
    const code : string = params.get('code');
    this.dowSesion(code);
		scrollTo(0, 0);
		this.userInactive();
    this.checkMoveMouse();
    this.checkTrace();
		this.checkMoveMouse();
	}

  ngAfterViewInit(): void {
		this.checkRoute();
	}

  checkTrace() {
		this.route.queryParams.subscribe((params) => {
			const trace = params["trace"];
			if (trace != null) {
				sessionStorage.setItem("trace", trace);
			}
		});
	}

  dowSesion(code : string){
    if(code === "logout"){
      this.router.navigate(["/logout"]);
    }
  }

  checkRoute() {

		const route$ = this.router.events.subscribe(() => {
			const recibedCode = this.router.url.includes("code");
      const trace = this.router.url.includes('trace');
			const querystring = window.location.search;
			const params = new URLSearchParams(querystring);
			const code: string = params.get("code");
			if (code && recibedCode) {
				route$.unsubscribe();
        if(trace){
          this.callRouting(code);
        }else{
          this.callAutenticatedService(code);
        }
			}
		});
	}

  callAutenticatedService(code : string) { //Client
    this.mostrarFooter = false;
    let tmp : any;
    const datax : any = {
      data : {
        paco : ""
      }
    }
    if(code!=="logout"){
      this.cobranzaService.getAutenticate(code).subscribe(
          (response) => {
            tmp = response['data'];
            datax.data = tmp[0];
            this._store.dispatch(new LoadAutenticatedSuccess(datax));
            this.mostrarCarga = false;
            this.mostrarFooter = true;
            this.router.navigate(["/aterrizaje"]);
          },
          (e : IClientOk) => {
            this._store.dispatch(new LoadClientOkFailed(e));
            this._store.dispatch(new LoadAutenticatedFailed(true));
            this.createLogError(0, e);
            if (e.error.errorDetails.functionalErrorCode === "AUTH010") {
              this.duplicateSession = true;
            }
            this.mostrarCarga = false;
            this.mostrarFooter = true;
            this.router.navigate(["/error"]);
          }
        );// para hacer pruebas en local hay que comentar esta linea [LOCALPRUB]
        // this._store.dispatch(new LoadAutenticatedSuccess(AUTEN_ERR)); // para hacer pruebas en local hay que habilitar esta linea [LOCALPRUB]
    }
	}

  callRouting(code: string){
    this.mostrarFooter = false;
    let tmp : any;
    const datax : any = {
      data : {
        paco : ""
      }
    }
    if(code!=="logout"){
      this.cobranzaService.routing(code).subscribe(
          (response) => {
            tmp = response['data'];
            datax.data = tmp[0];
          this.redireccion(response.experiencia, datax);
          },
          (e : IClientOk) => {
            this._store.dispatch(new LoadClientOkFailed(e));
            this._store.dispatch(new LoadAutenticatedFailed(true));
            this.createLogError(0, e);
            if (e.error.errorDetails.functionalErrorCode === "AUTH010") {
              this.duplicateSession = true;
            }

            this.mostrarFooter = true;
            this.router.navigate(["/error"]);
          }
        );
    }
  }

  redireccion(experiencia : any, data: any){
    this.redirection = experiencia;
    this._store.dispatch(new LoadAutenticatedSuccess(data));
    this.mostrarFooter = true;
      if(this.redirection ==="Firma"){
        this.router.navigate(["firmaelectronica/listado-negociaciones"]);
      } else if (this.redirection ==="Offer"){
        this.router.navigate(["aterrizaje"]);
      }else{
        this.router.navigate(["firmaelectronica/no-documentos"]);
      }
  }

  createLogError(paso: number, err : IClientOk) {
    const { bodyLogs} = generateLog(paso, LOG1, LOG2, err);
      let pass : boolean = false;
      const y =  this.cobranzaService.logs(bodyLogs, LOG1).subscribe(
        () => {
          pass = true;
        },
        () => {
          pass = true;
        }
      );
      if(pass){
        y.unsubscribe();
      }

    return false;
  }


	logout() {
    let a: string;
    let c: string;
    this._store
      .select((state) => state.autenticated.data)
      .subscribe((response : IData) => {
        a = response.sessionToken;
        c = response.documentNumber;

      });
		this.cobranzaService.getLogoutService(a, c).subscribe(
			() => {
        this.guard.logoutItems(a,c);
			},
			(e : IClientOk) => {
        this._store.dispatch(new LoadClientOkFailed(e));
			  this.router.navigate(["/error"]);
			}
		);
	}

  userInactive() {
		this.countSecondsInactivity = this.maxTimeInactivity;
		this.isMovingMouse = false;
		this.controlInactivity = setInterval(() => {

			this.countSecondsInactivity--;
			if (this.isMovingMouse) {
				this.countSecondsInactivity = this.maxTimeInactivity;
				this.isMovingMouse = false;
			}
			if (this.countSecondsInactivity === this.alertTimeInactivity) {
				this.openModalCuentaRegresiva = true;
			}
			if (this.openModalCuentaRegresiva) {
				this.changeModalContent();
			}
			if (this.countSecondsInactivity === ZERO) {
				this.closeModalInativity = true;
        this.abrirModalSessionExpire();
				this.finishTimer();
			}
		}, THOUSAND);
	}

	checkMoveMouse() {
		document.onmousemove = () => {
			if (!this.openModalCuentaRegresiva) {
				this.isMovingMouse = true;
			}
		};
	}

	finishTimer() {
		this.openModalCuentaRegresiva = false;
		this.openModalSessionExpire = true;
		clearInterval(this.controlInactivity);
	}

	changeModalContent() {
		this.minutes = this.formatTime(
			Math.trunc(this.countSecondsInactivity / SIXTY)
		);
		this.seconds =
			this.formatTime(this.countSecondsInactivity % SIXTY) + " s";
		this.dataM2.tex2 = this.minutes + ":" + this.seconds;

		this.dataM2.slidersValue = this.calculatePercentage();
	}

	calculatePercentage() {
		return Math.trunc(
			(CIEN_PORCIENTO * this.countSecondsInactivity) /
				this.alertTimeInactivity
		);
	}

	formatTime(time: number): string {
		const IS_NUMBER_10: number = 10;
		return (time < IS_NUMBER_10 ? "0" : "") + String(time);
	}

	abrirModalSessionExpire() {
		this.openSessionExpire = true;
	}

	abrirModalCuentaRegresiva() {
		this.openCuentRegresiva = true;
	}

	cerrarModalCuentaRegresiva() {
		clearInterval(this.controlInactivity);
		this.openModalCuentaRegresiva = false;
		this.userInactive();
	}

  logout2() {
    this.guard.logoutItems();
  }
}

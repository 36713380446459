export const environment = {
	production: false,
	// api donde se desplega dev: cobranzadigital-dev.apps.ambientesbc.com
	apiEndPointCobranza: "https://alternativafinanciera-dev.apps.ambientesbc.com",
	// URL_BASE a la cual pegan los servicios - dependiendo del ambiente
  apiEndPointFirma : "https://electronic-signature-dev.apps.ambientesbc.com",
	urlServices:
		"https://filialesexterior-ext-dev.apps.ambientesbc.com/cobranza-digital/ms-edge",
	environment: "development"
};

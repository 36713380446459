import {
	AuthorizeAction,
  EAuthorize
} from "../actions/authorize.action";
import {initialStateAuthorize} from "../states/authorize.state"

export function authorizeReducer(
	state = initialStateAuthorize,
	action: AuthorizeAction
) {
	switch (action.type) {
		case EAuthorize.LOAD_AUTHORIZE: {
      return {
				...state,
				authorize : action.payload
			};
		}
		default:
			return state;
	}
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CoreModule } from "./core/core.module";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { StoreModule, Store } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { appReducer } from "./store/reducers/app.reducer";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LogoutComponent } from './layout/components/logout/logout.component';
import { SystemNotAvailableComponent } from "./layout/components/system-not-available/system-not-available.component";
import { BannerComponent } from "./layout/components/banner/banner.component";

@NgModule({
	declarations: [
		AppComponent,
    LogoutComponent,
    SystemNotAvailableComponent,
    BannerComponent
	],
	imports: [
		BrowserModule,
		CoreModule,
		AppRoutingModule,
		StoreModule.forRoot(appReducer),
		StoreDevtoolsModule.instrument({
      maxAge: 10, connectInZone: true})],
	providers: [CoreModule, AppRoutingModule, Store,  provideHttpClient(withInterceptorsFromDi())],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

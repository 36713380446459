import { initialStateInactivity } from "../states/inactivity.state";
import { EInactivity, InactivityActions } from "../actions/inactivity.action";

export function inactivityReducer(
	state = initialStateInactivity,
	action: InactivityActions
) {
	switch (action.type) {
		case EInactivity.COUNT_THE_SECONDS:
		case EInactivity.RESET_SECONDS:
			return {
				...state,
				countSeconds: action.payload
			};

		case EInactivity.COUNT_THE_SECONDS_EXPIRED:
			case EInactivity.RESET_SECONDS_EXPIRED:
			return {
				...state,
				countSecondsExpire: action.payload
			};

		default:
			return state;
	}
}

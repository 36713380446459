import { ActionReducerMap } from "@ngrx/store";
import { IAppState } from "../states/app.state";

import { inactivityReducer } from "./inactivity.reducer";

import { autenticatedReducer } from "src/app/layout/cobranza-digital/store/reducers/autenticated.reducer";
import { alternativesReducer } from "src/app/layout/cobranza-digital/store/reducers/alternatives.reducer";
import { alternativesInCacheReducer } from "src/app/layout/cobranza-digital/store/reducers/alternativesInCache.reducer";
import { clientOkReducer } from "src/app/layout/cobranza-digital/store/reducers/clientOk.reducer";
import { authorizeReducer } from "src/app/layout/cobranza-digital/store/reducers/authorize.reducer";

import { negotiationInfoReducer } from "src/app/layout/firma-electronica/store/reducers/negotiation-info.reducer";

export const appReducer: ActionReducerMap<IAppState, any> = {

	inactivity: inactivityReducer,

	alternatives: alternativesReducer,
	alternativesInCache: alternativesInCacheReducer,
	autenticated: autenticatedReducer,
  clientOk: clientOkReducer,
  authorize: authorizeReducer,
  negotiationInfo: negotiationInfoReducer
};

import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CobranzaService } from 'src/app/services/cobranza.service';
import { IAppState } from 'src/app/store/states/app.state';
import { LOCATION_TOKEN } from 'src/app/utils/location';
import { ChangeGuard } from 'src/app/utils/utils';
import { Router } from '@angular/router';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { IData } from 'src/app/layout/cobranza-digital/store/states/autenticated.state';
import { IClientOk } from 'src/app/layout/cobranza-digital/store/states/clientOk.state';
import { LoadClientOkFailed } from 'src/app/layout/cobranza-digital/store/actions/clientOk.action';

@Component({
  selector: 'app-modal-salir',
  templateUrl: './modal-salir.component.html',
  styleUrls: ['./modal-salir.component.scss']
})
export class ModalSalirComponent implements OnInit, OnChanges {

  @ViewChild("modalConf", { static: false }) modalAll?: BcModalComponent;
  @Input() fechaExpiracion : string ="";
  @Input() ejecutarModal : boolean = false;
  @Output() ejecuChange = new EventEmitter<boolean>();
  guard : ChangeGuard;

  constructor(
		private cobranzaService: CobranzaService,
		private _store: Store<IAppState>,
    private router: Router,
    @Inject(LOCATION_TOKEN) public location: Location
    ) {
      this.guard = new ChangeGuard(this._store, this.location);
    }

    ngOnChanges(changes: SimpleChanges): void {
      if(this.ejecutarModal && changes.ejecutarModal){
        if (changes.ejecutarModal.currentValue) {
          this.abrirModal();
        }
      }

    }
  ngOnInit(): void {
    //this is intencional

  }

  abrirModal() {
    this.modalAll.openModal();
  }
  logout() {
    let a, c;
    this._store
      .select((state) => state.autenticated.data)
      .subscribe((response : IData) => {
        a = response.sessionToken;
        c = response.documentNumber;
        this.cobranzaService.getLogoutService(a, c).subscribe(
          () => {
            this.guard.logoutItems(a,c);
          },
          (e : IClientOk) => {
            this._store.dispatch(new LoadClientOkFailed(e));
            this.router.navigate(["/error"]);
          }
        );
      });
	}

  onPressButton(event :0|1){
    if(event===1) {
      this.modalAll.shutDown();
      this.ejecuChange.emit(this.ejecutarModal);
    }
    if(event===0) {
      this.logout();
    }
  }

  cerrarModal(){
    this.ejecuChange.emit(this.ejecutarModal);
  }

}

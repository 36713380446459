export const pinTest = new Map<string, string>([
  ["3221378", /*Funciono*/"4A1D6D1677C92C02DDE33FA8253EC8C28ED948AD3BEB3619DFFA8C29031B622E2074751E52C7EC77E030585567096960DB7576C9ED18AB21B14969C980307B9AF6D847B3C70AF50D18E9A6F33DF4BF82175B934AC07FCB6AE0370E863FC5F4621475F63CE62A7F62F05BE520C2B46126310432D4182104CD2ED9D652F68189BD3C9B770F04D7B5EC8BAE42BE07E2A4FDF63359D167A23B1D7C9BC99F3A74D1D562686D9DEBEE896928AF683BA72397639206A0A68FA6BD9946EA5B823E3F6B8ADC96D5F9B8C919CCFE8475D4527244C85B24D9F316C083AF0E2FBDCB96A8B6D02872DB01C0AB75314CC63D060D03DFB501366D09ED815D3C7CE38316245F813E"],
  ["3223370", /*Funciono*/"4E117CEE5524A2C8E7A4D8CA6F47F47F5899786DF36A6B5D42875E49F40EC35D0CCFC8D13C778EF89F9C4B6890F822E1EC04E4A1C1B8A5DE1DA9BBF536A0499467651676BDFD8AABC8614BDC9FE58C54C82536322AA211C15CADC18AE688F291C573860204F4A127D62E4C52A96072A6F25F87E3526036016325FFD45EFA0C6A0869E004E568EB7E111FF18C165CA834EDB0387D9F90FFB55D9DED2170FCE44CA166285CDAD60939E84BB31356027EC01169F7D15727C5CD5C186B86B9B89E0AA2FEBD994EA03D9425E41364DBFDCBD334028FBCD87A0013BD6A8CF1541835021BC2F605DC86AD63E08FC313F969CB79A7220D5A588FD9DE87A543171F314C7B"],
  ["3223435", /*Se quedo en offerQA*/"5974246E9744436A4068848465D687F7DFF8C995D755AA9F2B5B95EB3F41F4409E46A086B68A5E6407E1EEEBD38D80D4E9317D58502416118F554D87CBC8DCFAD221AECA974504B7C7246A371F026823A858E6098A88702FF26402C4765059BC118067B106D312277D506BA323F031BA144639EFF7AA0F77F9FCE416623A9B592067B22F170F4B6B43FB2DFB0CDE1644EF2E768D503628FB083EC3878B6A371B057CAEAC9A818343505EC1E6D65DFCA7FF4C62F7886533F1A175E38900CEFE5705AE3B5D1633351200957C76D0E62501EA2FCD4C1A6616CDBEB29EA21A543B9B580AA890325A59C9A205BC13ED4B4D833702FF0EC236BF8207555BA965498A10"],
  ["3223695", "597FBDD53E2C2C0345DD5D9248CA1F27B6658495BF643BDCBDB729D7442CE96371F82BF75D2CB6A29AA1B2213022C21217567B1329215722EDC158578894454C15D5AE99ADC1FEE5347AAA2F8C232C38F13A2EB5B384852CCE4B7C8F4AEFA49E829C07A4396D0080BC88A2948441EF6EE55B341B4187D80ECFC7BE82901E82C2ABC862D4A02D46082A1B31C32A2944F39D54D89ECA45929A0BAE022CD934C9A49B8126E0DE0F0B1DED674763388F6C1495383617AE556204867EF2126DCC14B3D71D87930EA461AE00FA8EC60591984007D74CD9942986EEF7A68107F47D62AB47D66892796296D66395C3BD0AA8F22D322DD75D18ABE48FFA0FDD95A2B56542"],
  /*ClientQA->YES  y StocQA->NO*/ ["1928278785", "51D2F1EB20A1A5640E90578BFA0CABFD22CB3661A2AE581D2EC22EFF1CAEFF0FE0FE29C3B344C77B524A063C7C0364F9E91439F79A5C4C64B23F0215A7C8E4C1BF9AF0A00B0189BAADB0ACBF92F38D008CB0488BBF3EB129DB1A9774C183A9A19EB2A089BD7EBFF0362C51D7E8A5F150BC01104D9FCF2C3809D0140AD0C2508C3BE9810B072F97D84CD67A8E12DDED0C1303121CED84512EF4905149E5374EB6C2926FB8C536825B23E84D1DA51E411B60D54D68F2DADA7DD2F292802CD1AE4DF6C0318F2C98172A582F42E6AD74BB8ABF3CE5A5450FA7FDD9216D2A8FA953E491678288EA63D8867FE8D1F06C41B26268AF2D66D80F607C480BFA91375B4193"],
  ["3240854", /*Funciono*/"4275497088E74259C598F52379BDE0ACFE072FC90FC375096FC30EDA8C3B9690ABB892FF2A68FBC73A8BB55978FDA990040964D285BAC37BBAA0442036055854E5254A45456D325C5244A81B021C9B4ED28CB2DE08C5F7D110216DF0F880E3D6CCFABA0DF2D6B17D486FF2988331083F459B2B618C4E629E7D44A7B24FC1BE3514F785B194156D6CA995956B54723ADAB44C8EB180A7FBEBA2B9C5834F140E0CB9E0723408429674FD7B0004352A27D9978145A72248D54F3EAF8388B1FD56F8B7C6ECDC5A4339851B0FF6806368E2032995A69C9BB6CFCFD158A7F0BCDB49418B66ED608135B05E083D8A6EB9E8DD176AD19B30CEBD00DC6A7CC2D455CD3277"],
  ["3256806", "77618A50D1E20F7856EF51F30262E15D8FA7DAB6838BB361C780F6BF75C0C3F8429D40D11B237AD4493B435FE69362994180D241318F875520D8AAF5A42A6C39B392D49BF5C6BF8B8C9FE7498B9144F3CE67AB130B25FCB799AF0E5DC0EC17E16C072D34259E550FDFB5B43A4ACA6EDE8A1068EDC8FE7E0ED62CDB7F15DB25F2F67F91EC75F41A4176FB8A4B091F3286AB91B4B2B122DEDC31CCCB4182AAD8F54960A676A658F5B369DA97D67F1F976637C204957F075A3B6EF897F5F54E635F5F2D780E33E5EED54C2BDC5B4CABF35755F3DA4F87F5FC57902EADF93944109B53E5D6FC7A39B0FFEBC54AD80D54144B2120DF0460D6204E53B6A4FBDC802DF0"],
  ["3276567", "86B479BF277167ED182802F2A35044A604397E883CFA0F7EB9B87CFED2F5BAE3C191BBCDE7E58FB2419F34CDDE1D6A2997C3AE5357ECDE5F7C5737F2CBDE43A40F422C386C68894C02929B655BDD182D01FC626F0BEC3758ACD346ED58D968A3520BD554C077E9105A16816AFDBB9C7C9C439A1BE7C0640D0D8A18D79B35154B215911E05F256C1D7AB68732BE0C50FC907A14EFAF9CAA3569630F8F62B1573030CE609DDB8C8AADF0C6221CBBDB839128753FE838D86C9AA26737A2EA834C5D0E713A224E44DCF4E0812CBB139520ED40CE1663D9D48965102A676FE168C8AB989D4D0D26A2536E7B0F9822F9A201F25A65B28EF17F14F8FBEE0FBD878CA68B"],
  ["3361212", /*Funciono*/"2E16F4E8C722563B490C75C74272B7DFDAE38723AFEF305A4AFB51DD1ED42405220E9A6BD2EF1A361466BFF49CC37504817E0F95AC119395E0D5D6BD8C050C1E540B7944CDD56986E025D463EFCB27B40AD437E2C5E8883CEBE86AC52750EA8949B980466486EE806EC1D8E78F827776C6DEFAC40A354B2A873EED9AB1C8847F7AA448E5AAC89A46460419F22563C1ABA5862DC7AFA8779855BFF51B690769FE785422EFD0727EDD4F556B29A94C72B7922B1B0E37813ED1C3AD9ED549F678FD9BBA211532FBDC279A924F1410957853559EE295B397046911B150232AF5E4E97BB5F52497B0B954A79CA541F3CAC36068167613B43F5DB2FCA3EBE135A600DF"],
  ["3373059", "6C05BC79DE0EDE1A24AC2633E5B121EAB4760F4D1E3A9DEF96CABBB0B3F3A96D95FBD32ED5EE8FC0127F36AC2E6B46B25BEE39B194D17E536B20C92178116FF2A042A974ED461C48F1357E7BC605E32CE7C844E6818499FD216163D84B4BECAA94592FB98E1572EFFF96901BE1B35E0CB8319EBF4AAF35562BE8E7A66360C013D1824D3E270B6F06BA39029137A6B7F74139833C0AF598C757CC9BFC28E99D00A54D0ECD8BE52BF0290FE9A73EB626044E0A12656B05FE20AA26AEFCFF8268BC96F61F15DA8A842BA8D6E5D461E0FD0D2200B113A2AE86A9849E50410BE277EA745CC12806C987752B3BDB50B0035B9B9588B0EC0864885E0FB4E85246015A89"],
  /*ClientQA->YES  y StocQA->NO*/ ["1928278786", "62DDE9FA224E4F671D271E6122B71357D098A6984BA9DCF40A9E8D883CAA16EA176CE16E28879C79818B0F6C376CBE8E9B33F0BE8F405D9CF4EF5E27CED3C6A1406AB26AE7606DC218B651301B8A4C363E9B48A683409594CF4863B5D8C3BAB80067D6AF8EA1363E3E87B3B5AF4037D5DD994D6A16D6728CF8058D3646A718EF54D8860D54E78FCE5079350A91EF1217B6FD58F87E265521DF77A7DC6B61B5071AD5CEB8F3489A56FA63355C55B6DFC1B1D0E853DEE7AF22D9CDF3787C603617E770E391F7F5881B40667731E3DEF8B38C190928111D85E90C4F760CEC30EF63B4830E0F66E645E49806B70B1F2DFEF8C711535722259AD6F35865F7A2A60D76"],
  ["3469306", "0407461F50C3702D0C86F549865A308463FE50F06CB4430665A9D1390F5361D0421A06D46D9C364A37BAA4D519CC229ACCFB59C51F50B9D38EF5FDD316C30A48E4D5F89D0EE28367C7139597753FD54385DA9AAFF4EE0E2AF7444A62D74915656564DAEB0DA140C54B6BA1019EBB4613FDFE8B8E69FB5B42E3E45B061CE9D2B21164E5BC03BC93C43657F4766B8C51C1FEFA6A26FE51274C4B6DC8BC5AE68739BF94B23C52061D1082B2D28F13B331BFA3C233B82D0261F87E6A4F4A60BA6B2B7269DA00849761335E9A8A608B67D741B8F60E3D031A3ED2A02578ED804B957BF9DF6DA8B1319D1B3A7AB99E338F0C47069F64B4516E2FF0DCE26D91F27094AA"],
  ["1928278787", "59FB2D7ECEAB3E7533340DDE6A73D3BB8C1B49DAE9BB6FE43A3CC4D80C90083D0A121BD92D2FF62A2C5A1C83A1A9EC4EED9017670ABF105375E8E2676EE30CCAB72C63E54D15927FEA20CF75CB38B496ED188A25A3765FA763A1D992FB005A61DB63930C55E282304220B1F35AF9053192BAF70929C828F72BC7C05A5F1931BDC011DA6F1C46C1CD3C09CE086737BC0A831B7AD27D5D469DE5BFC70260EE25F2C13C7552136A3022582793FFA7F55C6F9D0699985E72545C5CC3DAAC79E55C2748F038D0534F26F67E674F8C68709FF4B0D00ADF72E73F6EF7053D0AC151527DD832BB1135EB50DED25F7C96684F501D0C365A0763908B21F704DA94D7258AC3"],

  ["3566033", /*Funciono*/ "544B1A9245FF77E5516014373FC7A2ACD668E0A84C968197AFF77CA8573952C5DD9EF1DE52BBF196C8F0576541C49E96A00D26C570B953548AF726F753EEA60C582E82EB857D04BF780E5F3395582BE943C575A544EE8A406B36D40935D96C88BD88DD0028BAA6B02302E6C49D38DF3C0D42EF6603F3B889D7760623AFC29AC7D840B105D23333778023BD901292964DD6143F710B86404757CBA064921BCDF91E2A936798D359847CC68769DA6D18BD02794BE5EDE3E575455D993288632D494EB4244653C520FF107ABC79EBDC5A6F3918A749724A2352D02562AE5D904B15B928539B47F842A92B563515F64AD773FE1A398CBC2E58782E4C8B6F57EF2FA3"],
  ["3912350", /*Funciono*/"3DDAAECB511EAACFCC8FB4FECAE06976381AA7E346DF9BB226FE8E02880AB1C7890CF248B75F302D7642DC849D6855BD3F28AACC9F1453EF9EC72A1434916D9390E20E2DEDB9EBC7C3FC9406DA281033C6D43FB36CF98B22B07C4CE5D6D823AC3D96A8F480F4F44705F69D77B88C24D8BD8931C0EAEB2433054D00BE47F3F06486F2A0882E134F93E2882EE8BEDAE44C553EB57E29A3536946AD0D2608EE3981CB07D3ECE6F015ABC91AFD0A47AFA440FDCB9A82F069B2B84557982708A3E66C2F5612BB1749BEF3B5FB01577A7D8F95B476843A92FCA70E7F83B7634167AE70BC0376DAC01BD73F7CA35B6DCCA610B4115654C4C5D88FC54FE49B027746FA9F"],
  ["4211865","09F45F60DA4BB323E7406F24CEE8098F64847027C4CCD4326D07F52069DCD21C807AEEFE24E2787B42C608717C4A2CBD8EB6A421C10149A5DB21DCFCCC28DBCC441746E83B6967F31F2B7AC4FD9F130C88E203A5A6E4032EE119CBBD1AF5146C69DFB457215FF881E613CC9D260399734B1889B25D6DC543A2BFBE0B6DA39DC3930218781CCECC97276A837A3428349EA6A924AB44D0746165662A8D6DF8ECB9343B0634D2E265E6C1102C081ABA4705C163136C7FD6B811E7F71D224B851A0E37E2E085A09C3D91A74EC3CE9EFD4B3C956FB7DBEE363B963EB23979872A7E9C32A23D8DECF75DB787F6319C29CF8F61239732D9F5E9BB4BDABB682A9B0232D4"],
  ["1928278788","05D61F1AC46FE732FF23B8CC77ED8F4B7E9213681D007BD20EEFBC588FD516FE9ABEF6ABCA1B285C8586D246655705C3469FE2D5318DB3048705B1DEB743C3A3CDBA455A2B46ACF65AF49180905BC079084118C3344AB40DB27F42EF816F1C983E96CC32A930B5100AFACAF90D4998EAD66E840940308E2A1EDF40DDC24EA632CDDECC4F24B1E52F9ABF00B7A0FD7D4B9EB101C565F3E397C3114EE905395051918B7D539F479540858DC153E8F54FE391D1B7F2D735FDAC5B379D22C63B431B444CC47617130AFC46A835B3B808DAC9FA203546197137CC9A5C3F450556801CB553BE33FB565BE16FCB2C665A97B9745D441305B791C0354D5E0DBFD7D63972"],
  ["4655107","4831F1273D4519E5CE93E44BE9FA03B8C083E866FBD04B13B9173DF593175378F70315139DD9C51D001C8BC6AC5297A6FDCCE6133D304E3E1F085C41F6DB7E61A26B340781EA3AE87631B69472F475279D84FE8F2F7AE568FE1B6CAE75F414B3B054CD5237F54886105A53C25EE9DCE0C4ED1556328A1238EF74F4D9B22D1737F1F17CF9290C27ED83C9B605B603240C55E19215B5E88DAAA6C7FCF3FB1FD6AE18696E285FCBDD1EF3676D69282012C01A01E82E78407DB3D9DB3F7CDFBAE703CE3334622B6868C98963E02A07FC0269AE957B933C950972339CB4134EBE6C183CE7A453EC3CFD0684750C5383EBD31A789625C6D856C4E4B6D8693CB3F96DF4"],
  ["4829622","05AED6E8C28355D8088470524BD992ADE16BB6D04E36A8E1FD52CE26D998CAA27ACA7F9023B6522D6C44091BB1C436AD4FAC4B34E2293CE53AC21696F1A4BEE48AC1C2982A14C95DFA7FCB2B12FB869FDE766446415AE962E744C7E39280DE4CFFC12F5AE3AFB4635F633A298596595892F70119BC7251E753B4DBABCF20AF2815F1C1B4513914F648287B90F1F51F33E0EA477538255F7DA76742962CEEB2C071CA696DC1DAB834B32D19985DA42864CB196AE4B160D6A3053CB796E727DB936134A7352797862674F79E04F30BA237C1AA37CEE147FFA818DF6650361C7A6155DC2594E9064CFF0E166D505C4CF7A9D3319B426E3928E48C1EB2DED8DD3F4D"],
  ["4979224","427789D3AE5644435E298BBD340DBE0341C680498FF93E0159EBF4F7AA9BE3761DB1F48F541303A1C3A64588F9F32293424889C70DD5B9A2B9E9F915C3F07BC3E4FA349A711EF1234DDB167CBDEA848F00AA4E19C0139A35662B73D19BC232C49D2D25F8EBEC5BE12CE9107EA6FCACD273893B0A6754AD3572EFA88DCB43EB2C87099ACC5AA8F1E152E201BB0BD8356225FEEA6848959F79B9BCDFB1E76855E816FCBB0B5F659233FE96E3F1BBC5F850206C2BFA3A89501821B465FB507EDEE6E88D7001A1227DCDA55307093718A3F09EA5E80BBD5B99E65D79648D0C5E602429E3FA4C8E5798A3C557EA2906A1A1C50CAA067C2F95C4C9E6348418B61B5333"],
  ["5030137", /*Funciono*/"66CD301E55511062F52A7649B48E5E90301BF63C1BBD7FA9E1E33F63EA1748AD23B60688C585A98B7DEA1E7F6E8C46B4C8BB9BD2050892637B3ABDF61DEC5654AAA045413B673B8702A14A673774D9DDCE3BFD56B15AE8E3CC217A744E22C1295257F4D999CCFDF816677B423698E4977969CDA183E047F625FA9C94C0DD01CC0D6A1FD4B57BD4FF82DCA08223CEA24452B5709F132580C0773B3AE322EED2DD25BFB764C03ECA49EC6F89B6A67CB3B3E1AB1076240E66FD4B46D0CBF613F9818C5F4BD0A0D035CC9947E523CF8EC1F4D558CD91F0BD9A8F98ED87AEA9F069BCFD3B104FBBAC7F4502F18EFEEBCE430E84921CD2B8965E9E0073B1E3DDD5E9B7"],
  ["5689556","5003A503B5AED0BD28C49EFA5876238B6E00AD03390B97FB7FD2C9571C153A0FA12C3146DC54EFA2F404440355B08787EC428BF4949BE65E547E9F80F3AFAF60B46419FE64201922BBE792844DD0A4D70D9724CDF490DC43F797AC32A7335AD10C35210604BF61EA56F6FE0FB68D77971B9CC115294115B6C2A5E363F0F5A4F7DD21B06056D387634BB9D9ACCABCE55E19E0DEDB63DA8FA05FFC7E802E175A2555B46F6EC021BD6A5A1084B2733DEBD904AF6C1DFDB00A9E1F0D0C58AF62CA4AC2D8EA9F2F99ED2D30DB7D141E73CCFC7F2FAB1D18785217EC8058D41134BB55800744277C2BB9ABB264D5A618973E3D5A0888B7C3B75F1F4D6A822848B3D6B4"],
  ["5729155","1283C24B6A814636ADCF95656B5B7B6B49F9B9F04C7798A8C8B8B6C2515558139296C81EA3D2FEC7F8CEA45DE25499171A274442A12AC843B46FCA61F444F7C0129065C74E0EC42E34F3FD2B2CC5D2BF20B21D8810B81F11052901A14168F5B5E8A84E2C12F07C25D02150BAC77CAFDF5395454620125DC83FF12149D624EF6B5A8B8CE97F01111FD346A38D7BDD8CADFEBF4D83893C3A3542FEC4484DCF7FE9D3A9118477A89BBD7A19C1378841ABF54A12C1E31519A1E277F951FE116AB26538F1193C303FBABBD3E56F1BAC39E7710CDFC4457C6E3335BD932EB4EF5BB73FB69B23DD58E59F7C7EA7528B3314E46B9FDE93C9BBF980DA2B13A6BBFC5E0EBD"],
  ["5853541","026EE82A25106BBF0733CDF61941E337C630692989671FD5917266A26706637D8B20A91BDBC3FD48C5B2F3F6FF4D2E5CA00674ADC670CB17AFF785CACDE97841CC8BF8FE91E9105D747FB06B9D9D3E9F9EA3E97B763AFFD383CD6AF4D5FDA3064189474B630C947B4C5AB6F27C17BC1E1C13E15977E1B18959DAFF0C14FDDEC0176A2185E66EB9DF7CFB15815F7FD114E777256CC42F26AB49A481F713FB322DB1BD7F788EDC56572BB258BE1DF65730C660F48EFD045E61A4D07B7999A059EEF23B05A5A3136C5EDC6C8E0E27F5CB9EA974BD89C51A8512CEE9044F82214914C9AD2DC8235AA8AB351565FB9A19A7886FCBF67D8BC5B866115BE4CEC58D37DD"],
  ["6009025", /*Funciono*/"172B0A027F198DE33F379C65F920B4CB9A0D7161C764A1D10BA86B93E75F1F7EAA8DD08CD4788962E603EC0B2391B8A4D25D5A9C5A96FB63A23E57F069EC5A8625D847D4D5ADE561756061953B0172943E645D41A74F2E276E0E53FB33AF80110389922C44F08C257FE33E0F45EF006CA7E5370F49734A0C28D7EF59F2F7D3F8940C760E376F73C38103164B3F6D57E5867B87C7124CDCED68F0B9F39120A3553FB742230A7B9AE2F0097C7656CED8691BDDDA8C632052077C76B6A21513E981D6E0CA650A75977AB7F995258F6AE5CFA93037329522BBB0A0ED1EBB209B768DFEAA5E02A18E71312AF5FE53805A0666DC88F7463C0BD76D7F8DAD1175102B4A"],
  ["6160757","681982CE1A2CC6E7C4D78578678FF874C87BCCA8989C42246088EC0B7E5501B766361036C07E0B0C02CCB3554CA488B73DC9E1E1C6DD1E1CD31073FEAA8EF508992E2357969B6F2283E1281C02607A58BCE7F6038A9A9EBDF849B4FBC70BF91F855BAC85BCA3416CF39AA8CCB9789922CFB450662652B7AC22041ABB4D2BC3B94AAEE8EED15DE1B776FDCC06BBCE1AEC046A1BED39638102554B286CEFAE123963CC70BA4633EC5C6D5706013F95D13436EDEE18B4235FE66DC5192A8BF804C898FFB820F7DB7F706B4D6904BA3DA125215881C2FCFBF1ADE1D54B9C305CD16B9B75844B82341291C183860473091C47B027EF6E4EC3CA839E8CC9D489670608"],
  ["6390060","560398614C95DB91CACE18278DF640BE50F0B83A1E03B804E0117A06B3EB2D531F0049DD50192946A922FE7A9DF823538E30738C16928E4C787EBD232BF764DE2AE3B06D3C216DE46B06EABA220A5E0BD7134699F79A3E9E3A57D8912EED5F83570EA447F1970E9528D32C3BE9519174EA37641D17ACDC14CA0AD09346D681ADC05A46EA2136211386F01B88FDB8718ABBF377E0A799F2300F2EB521E341162AE270755C57E65A3EE09420CE3373D957F60C7ADA8C132F95CB66C9E262975B0B1441AA786ACB4EEC6ED18E5F08B2B2DB7D1A45436DA7702E107FD438BFD1925FA475EFF6348578C29471B509EF986F97810967EA7AE73237452246BB40389DA5"],
  ["9657444","6726B556BECB2CD175EDC31F1A8324911D8BA6D99EC2149A33178E89172349136B13726584658D68ADAC1575BC008C245CE4B7EF5B388B47D238CF58DD780379942292E91FCB3DDD4B0366C2853AE96C2372CEF33105110A1F34F5F2CEFE3CB20CF794114F84D42C9059A202421C34E3175AC92D8B120A5A59A910BB3AF0DA4C88DC9785E84702068FEB96EF342D9B9BD2D128E25132386B2192FB35FE27946826779F1BD3F31B3FF9FADCB1C7EC5F8F229CF471468B813596E936097A9B3FAEA7EBC6A21192A379D8BFD89DB0ED39F277708F8518750096BF9F116B5F1428EE00973574E37C61B656840C71AA13B47CCFD1B6F2B5FB8F6B062AC31455E6EEEA"],
  ["19709113","49292490B1D4C0320BC71806CF726CFA66250FD3F5B6DC740B9A4338E5B5B8FF5CEF9EFEF24D1DD1ABB128D53ED3D025EEBF178E9E53D54DD31544F07CD960749BC9B571746AC1F912025D70A422641A4587DDDF03CB20C77997236B4C9F931E9D09CFD39FD9B76F3A11C81DEDF7F6CDFCE2AFC68FEF79C2CB28E3A453F76C1F47EA5142D0CAE2AD3D01B8B92ED23CA79FC2F92AAA93608E77CFCA075C116F3F342F9FA570CBC25569E76A0E6BD414896B1A246CCB89E42F1663F6EC157202974BF17361B2329B5D43498324B14456F95543054522031FB5E17FBA74E1AC844EBA155218B5E56DD0B2BDB2D75889D6DCA8D3C3BDE4DBBD3562ADEB3A381AB7AE"],
  ["20933091","7FC9FB5767E65AACAE5D20665119453DC81659A8E7FEC73FCB6FCE36CECDA125F6D59CC6ECC5ACE0D48D196EC27090C36D99A2292C3E7F716DBDCC2756621067396B172C300CEFEF8D37C16CA9AAC41B638318079A7D2937ED415CB3EE37E1BAE7CAA3303931FA829BE6F3CEAF3F6E0144ADDA0FA28987ED642CE98FC79BABFD0803A397B9C1A4A34312DF2E3B80F8E7A588996FFEBB82D3C2E4C844B04F8D2A84E0F8F2AFA395E18E1FC1FCBC3DF555E9783D0114AB7A23434D903C8C014A008E099124CFA001F689A5AAA4E86137B4506916B52F5E86BFFC0965901B10604A9131C35D1B2651A2109628C8831233FCE23D0A445A2018CAAD67E5DA6D607553"],
  ["24010494","65696058C05FE3BF32555BFE2FBF2134F26FAD1B28D3A4C60B51935E3A198FA6FD31B4E11FAB3A19B5F049D3A13BB84E8AA6F32D877071D63F4B51B00A9D1FD6544D13B3A33CEA9335946467F2C1CDF5C7A7574BE6DEB2084870DC5C776F4463F3A2A3A6E4C146F451C5ED67BA103D709FA48A8CB5D28A0C0F95CFC0190706A0CC8A8D8ADABC9849A9CE521E1F59F5D1F011518879744ED5B8BC47B2F494BA749DDD93E5114E1857E2780049DE36D858AB0CA85A2900BA9595E10525F7CB9E890A710139BB14576051E3B9D62D45245A717029900F311138EC5FA2528BF01BF788D5C1D974C90E61DBE8C3E4A617E1B8503D9E9DBAB77E3128FE1AE145DAD1A7"],
  /*CLIENT001*/ ["1928278789","3CC19584FF976985DFFEAFD5F433003D831BD5340D91D7F4A084F2EC970A5AD0B06E43364900000AE37C7222342F6DC9E30433955C07A93575A6F5923928E5D8E224571E5BD133E4D7679714F1AAB91341A539115407AF61091E67350222E7348B14FC852CE50D0F9FE8D4EE93815B50360D7DF5CEB17842A87748C4BCC9759D29A704BB956D075BE6378AAE70181DA2AE906EC034907CC1B7C6DC13DD068A7CE6212AD0BA29B813D9FCC5C9168C312279095F28E1505E2ECC0E543845ED895B5CA6FE28EC86FF956AB06A1DD576410D6188FA42FAE9C5D12BBF22D8A307C32747B678C891DD658BFBD5BFB0977EF9103FF089BBD2BD2F406715BBFFF7340F0E"],
  ["901011406","04C486A4848627777A3BD2A283EB60E6B1CC2A9C9B000D8C651103FDCA8A9A822B5A51DBDFBE33D84CBE59DED096AC219260AA4AE31166D70DC299D3EE0325F8ACC0FF783EE60B58B4B7CBF44862C55DC6A86831B19EF32918021894AD8FD51265E4E7B55A125DD03883999BDC4E59585DD06BCD2E30313FDE8A76EB9A7B0F1281BDE5D6A3DECF0B28B02BC595EFC8E45CFDB3FDC205EAFCC6D4E2F526DBAA3FA536E1CDF5CA1C265E46ED7B4232E3486A47F2D254DF1E5C29D8970B3C0D1765DD846712C1CDDA933489D29055DEEB8AE4C660C2C8E5D856F263F3138DF6D3C4F0317D85BE70111CAC6895BCE6C69E78D7F8A309EAD3B85FB59D6939B35475BF"],
  ["3351171","5D6722F30DB031F0F7DCF0B22C18B0186938B0442ACCA3E556C6DC2943036DEA405A135E93D5F39227D3040CCB61636D8C2D9E82781F2AC4165529F6B73559DCE930FC2EB47FAA6CA881E9380CD29C9AED62D4E8C7646E00C041CD6A859FDF69176067324E7F964D9A0917F45F44D0F38777CF1CA6716E5E726ADAA29F014E12135816215B5937E11C483DD54B63826F7A9EBC7DBFEACB39FCC2A58285CFB37FE1D65D7F5AB38407681B3DD0EA72EE8336B221C034844517EE33B06CFC5850F686C343A27D0231DE8F7BDC3777636D1975B200B4A80992FF68B4B4B50CDF1355D25802F2752DF6086FD3EC3015A13C544B4EA87FC382BE0648357053B2F2ED35"],
  ["3352073",/*Funciono*/"67CF37E514D05E18993CB6399C300DB956549033FC82D8944FF7CEE5009BB3CC7D6BEB8EC7E3B2967D985E3BB194496C2D774B0DCC3365FA4F5E261C6D1E67302A87EDABEC2D624730A66ADFB7E61889A993929FF57CF53DBF1042D8D7C0D3EB6C8C6B94689A7DDB4DD3FD2EDAC504A07A85021358DAD570F377157F40B55BF597D797A0CE02EF96E02363C432035C7D6351F7ADB1E277A606D88FF0B7385623B17D6BFC9FE0E7C1D9443D27BB50AAED09132BC7BBA234D16920F91F85707A36B7E8A7CE8DBCBBCC0758144B4CE4528E49D4FC56AFB0EDF4BCC4D401489AC5B285F06E3F15C64A470A1AA1B6AE98CD6B4576A722477AC230CB01C426998A7B96"],
  ["3352548", /*Funciono*/"5023F03819FB1FA818901EE950944497C0A48C93CF7764DA2B70697DA22A24F244D4A410C63AAAB72E00A2FB47362307BABD91DF92C821AD7F2CD1D6AB7C34C36768DAC65F9F6E100B1285669CC90F3F74C64B5671F4BED647C5F0C1152682CC90B6F49704E8BF93E2ED0752E11C107B6AE4278D3C7688E22B4A21665491DE4E4595923D6319F3D8A34F193F9407B39E6064182E0946628535FEBB53B0B940F5B4D37E77E456AC4C1E18380A551CD6836BFCA948BF53C3C3422904D69AA954039B3AF0071AA13E97CBC0A9DFB8BB9F27BED790F69A3DC4B0342E56D00D5F6888AA8C11AA39402183E99D3D3C8A8D2AA85B429FFA96F70ACF3B321C84F138E93D"],
  ["3354654", /*Funciono*/"6F27EE6DA0326D2DF34E6911FACC858EE298B00D6B7B956EC4B86EBCFFECC0795E8900BF5740E602B940352A6A028BA08B9B23067D59232D5263D58E62178D71797F3C41F5FE1628C319828A2581A7881FAF135821B4ECD5373914972802D8A4E3764A3E0DF99E98BCAED29809213AAE65AA06BF89EC9B2E3477446861EB88A26FDC223A719FD38D47C5D0579F71E1901EA07FC3192EA2EB5DC2DEFA17EF3FF67EDF50953123B0193256399FD32085DDF968DEF1BA331BA9BFC74A03D70C095958CF340C800A39F0D069DC9FA27DAB86E6024F5E26522EDCBB92BAF6BB71D89C4E37E22A7B13DA99E5A94E8A6E8CD62B5EC0165B314655235CB24BF857FCFCCF"],
  ["3366072","158026A8B3D804722C49D00D8CDF04EE31E63D73CD1CA60EFCFA1686BC3E980837992DF55A980BFF693F7DD9D1EE33320241B0F1D9E01736D67A8F23644313DA3611A0D09A82D376CE268A916AD17CC7E90C3241F100954BD6E06319BEEC78C2614325A9F285C46517948D4C98160445CA7DE87B2AEFD1370147D508B30C4EC6A0860245EC58E246EE1FC23DD673C44190AD8B553CC8722941786317E68353C21DE3A0D7593C6D52CE56BE609A89344D5EE3DEDECDEAE11C45C0A06349E8D59A6C88AFA98B6B59C6871C246602FA278E345320A94AD36E20A12B968CE3CEF5CD38B909B91FB51F5063F09AF7CE95E93A95F3617F8F9323A1E536F33A0879B2B0"]
]);







import { Component, Inject, OnInit } from '@angular/core';
import { LOCATION_TOKEN } from 'src/app/utils/location';
import { URL_BANCOLOMBIA_PERSONAS } from './constants';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  providers: [
		{ provide: LOCATION_TOKEN, useValue: window.location },
		{ provide: URL_BANCOLOMBIA_PERSONAS, useValue: URL_BANCOLOMBIA_PERSONAS },

	]
})
export class LogoutComponent implements OnInit {

  constructor(@Inject(LOCATION_TOKEN) public location: Location) {}

  ngOnInit(): void {
    this.location.hash="no-back-button";
    this.location.hash="Again-No-back-button"; //chrome
    window.onhashchange = ()=>{window.location.hash="no-back-button"};
    this.logout(URL_BANCOLOMBIA_PERSONAS);

  }

  logout(url:string = ""): void {
    this.location.assign(url);
  }
}

<button bc-button typeButton="ghost" (click)="abrirModal()">
  <span>Cancelar</span>
</button>

<bc-modal #modalConf
[customWidth]="'small'"
[isCancellable]="true"
textBtn="Volver"
textBtnLeft="Salir"
[hideCloseButton]="false"
[displayButton]="true"
[buttonAlignment]="'vertical'"
(stateClick)="onPressButton($event)"
(closeModalEvent)="cerrarModal()"
>
<div modalContent>
    <div class="content">
      <div class="header-text">
        <bc-icon
						aria-hidden="true"
						[name]="'mano_1'"
						[aria-label]=""
						role="img"
						[width]=""
						class="bc-sm"
					></bc-icon>
        <h4>¿Estas seguro que quieres salir?</h4>
        <p>
          Estas alternativas
          <span>
            estarán esperando por ti hasta el {{fechaExpiracion}}
          </span>
           por si cambias de opinión
        </p>
      </div>
      <p class="texto-modal"></p>
      <div>
      </div>
    </div>
</div>
</bc-modal>

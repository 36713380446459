import { Action } from "@ngrx/store";

export enum EInactivity {
	COUNT_THE_SECONDS = "[Inactivity] Count the seconds.",
	RESET_SECONDS = "[Inactivity] Reset seconds.",
	COUNT_THE_SECONDS_EXPIRED = "[Expired] Count the seconds expired.",
	RESET_SECONDS_EXPIRED = "[Expired] Reset seconds expired.",
}

export class CountTheSeconds implements Action {
	readonly type = EInactivity.COUNT_THE_SECONDS;
	constructor(public payload: any) {}
}

export class ResetSeconds implements Action {
	readonly type = EInactivity.RESET_SECONDS;
	constructor(public payload: any) {}
}

export class CountTheSecondsExpired implements Action {
	readonly type = EInactivity.COUNT_THE_SECONDS_EXPIRED;
	constructor(public payload: any) {}
}

export class ResetSecondsExpired implements Action {
	readonly type = EInactivity.RESET_SECONDS_EXPIRED;
	constructor(public payload: any) {}
}

export type InactivityActions =
	| CountTheSeconds
	| CountTheSecondsExpired
	| ResetSecondsExpired
	| ResetSeconds;

import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/states/app.state";
import { splitHour } from "src/app/utils/utils";
import { DOCUMENT } from "@angular/common";
import { IData } from "src/app/layout/cobranza-digital/store/states/autenticated.state";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
	datax: IData

	day: string;
	dayNext: string;
	month: string;
	monthNext: string;
	timeHours: string;
	dark: boolean = false;
	constructor(
		private _store: Store<IAppState>,
		private router: Router,
		@Inject(DOCUMENT) document: any
	) {}

	ngOnInit(): void {
		this.getAutenticatedStore();
	}

	getAutenticatedStore() {
		this._store
			.select((state) => state.autenticated.data)
			.subscribe((response) => {
				this.datax = response;
				this.setLastVisit(
					this.datax.lastEntryDate,
					this.datax.lastEntryHour
				);
			});
	}

	logout() {
		this.router.navigate(["/logout"]);
	}

	setLastVisit = (date: string, hour: string) => {
		const { hours, minutes, seconds } = splitHour(hour);

		const options: object = {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			hour12: true
		};

		const INDEX_2: number = 2;
		const INDEX_3: number = 3;
		const INDEX_4: number = 4;
		const INDEX_5: number = 5;
		const INDEX_6: number = 6;
		const INDEX_7: number = 7;

		const dateAux = new Date(date);
		dateAux.setHours(hours, minutes, seconds);
		const dateCompleteAux = dateAux.toLocaleDateString("es-MX", options);
		const arrayDate = dateCompleteAux.split(" ");

		this.day = arrayDate[0].slice(0, arrayDate[0].length - 1);
		this.dayNext = `${arrayDate[1]} ${arrayDate[INDEX_2]}`;

		this.month = arrayDate[INDEX_3];
		this.monthNext = `${arrayDate[INDEX_4]} ${arrayDate[INDEX_5]}`;
		this.timeHours = `${arrayDate[INDEX_6]} ${arrayDate[INDEX_7]}`;
	};

  get hiddenNav(): boolean{
		const currentURL = document.location.href;
		if (currentURL.includes("firmaelectronica")) {
			this.dark = true;
		}
    const hiddenNavbar = [
      "beneficiarios",
      "asegurabilidad",
      "aceptar-documento"
    ];
    let yes = true;
    hiddenNavbar.forEach((element) => {
      if (currentURL.includes(element) && yes) {
        yes = false;
      }
    });
    return yes;
  }
}

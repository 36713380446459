import {
	Component,
	ViewChild,
	Input,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges,
	OnInit,
  HostListener
} from "@angular/core";
import { BcModalComponent } from "@bancolombia/design-system-web/bc-modal";
import { DataModal } from "src/assets/interfaces/interfaces.state";
import { DATA_MODAL } from "./constants";
import { Router } from "@angular/router";

@Component({
	selector: "app-modal",
	templateUrl: "./modal.component.html",
	styleUrls: ["./modal.component.scss"]
})
export class ModalComponent implements OnInit, OnChanges {
	@ViewChild("modalAll", { static: false }) modalAll: BcModalComponent;

	clibtn1: boolean = false; //Boton 1 seleccionado
	clibtn2: boolean = false; //Boton 2 seleccionado
	clibtnCan: boolean = false; //Boton cancelar seleccionado


	@Input() botonPorDefecto: boolean = true; //No muestra el boton
	@Input() ejecu: boolean = false; // Abre el modal

	@Input() dataM: DataModal = DATA_MODAL; // Data por defecto
	@Input() customWidth: string = "medium";
	@Input() titleAlign: string = "center";

	@Output() clickBtn1 = new EventEmitter<boolean>(); //Evento boton 1
	@Output() clickBtn2 = new EventEmitter<boolean>(); //Evento boton 2
	@Output() clickBtnCan = new EventEmitter<boolean>(); //Evento boton 2
  @Output() ejecuChange = new EventEmitter<boolean>();
	buttonAlignment = "vertical";

  public isSmallScreen:boolean = true;

  stylesBtn: any = this.dataM.stylesBtn;
  currentURL :string;
  modalFirma : boolean = false;
  botonSecundario : boolean = false;

  constructor(private router: Router) {}

	ngOnInit(): void {
    this.checkScreenWidth();
    this.validarBotonSecundario();
  }

  validarBotonSecundario(){
    if(this.dataM.texBtn2 !== ""){
      this.botonSecundario = true;
    }
  }

  checkScreenWidth(){
    const widthScreen = 767;
    this.isSmallScreen = window.innerWidth < widthScreen;
    this.currentURL = document.location.href;
    if(this.isSmallScreen){
      this.customWidth = "small";
    }
    if(this.currentURL.includes("firmaelectronica")){
      this.modalFirma = true;
    }
    if(this.isSmallScreen && this.currentURL.includes("firmaelectronica/firma-otp") ){
      this.stylesBtn = {
        width: "100px",
        background: "#f4f4f4",
        "text-decoration": "underline",
        border: "none",
        "box-shadow": "none"
      };
    }else{
      this.stylesBtn =this.dataM.stylesBtn;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
	ngOnChanges(changes: SimpleChanges): void {
    if(this.ejecu && changes.ejecu){
      if (changes.ejecu.currentValue) {
        this.openM();
      }
    }

	}

	openM() {
		this.modalAll.openModal();
	}

  fnBtnCancel() {
		//Boton cancelar click
		this.clickBtnCan.emit(!this.clibtnCan);
		this.modalAll.shutDown();
	}

  closeModalHideButton(){
		this.ejecuChange.emit(this.ejecu);
  }

  onPressButton(event :0|1){
    if(event===1) {
      this.clickBtn1.emit(!this.clibtn1);
		  this.modalAll.shutDown()
    }else{
      this.clickBtn2.emit(!this.clibtn2);
      this.modalAll.shutDown();
    }
  }
}
